<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <base-card>
            <v-card-title> Current Trevor Company </v-card-title>
            <v-card-text>
              {{ currentTrevorCompany.name }}
            </v-card-text>
            <v-card-text>
                {{(currentIndex + 1)}} out of {{(trevorCompanies.length)}}
            </v-card-text>
          </base-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <base-card>
            <v-card-title> Search Zoho companies </v-card-title>
            <v-card-text>
              <v-autocomplete
                :search-input.sync="search"
                :loading="isLoading"
                v-model="selectedZohoCompany"
                hide-details
                :items="zohoCompanies"
                hide-no-data
                item-text="account_Name"
                item-value="id"
                placeholder="Search Trevor Companies"
                prepend-icon="mdi-database-search"
                return-object
                @keyup.enter.stop="trevorCompanySearchEnter"
                @keyup.esc.stop="showNextCompany"
              >
              </v-autocomplete>
            </v-card-text>
          </base-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="showDialog"
      width="500"
      @keydown.enter="dialogPressedEnter"
      @keydown.esc="cancelAll"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ selectedZohoCompany.account_Name }}
        </v-card-title>

        <v-card-text>
          Are you sure you want to assign {{ selectedZohoCompany.account_Name }} to
          {{ currentTrevorCompany.name }}?
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  mounted() {
      this.$courier.Companies.getData(1, 1000).then(x => {
          this.trevorCompanies = x.payload.filter(x => !x.zohoDeskId);
          this.currentTrevorCompany = this.trevorCompanies[0];
      });
  },
  watch: {
    search() {
      //this.selectedTrevorCompany = {};

      if (this.isLoading && !this.showDialog) {
        return;
      }else{
          this.searchTrevorCompanies();
      }
    },
  },
  methods: {
    cancelAll() {
      this.currentTrevorCompany = {};
      this.search = "";
    },
    showNextCompany() {
      if (this.trevorCompanies && this.currentIndex < this.trevorCompanies.length) {
        this.currentTrevorCompany = this.trevorCompanies[this.currentIndex];
        this.currentIndex++;
      } else {
        alert("No More Companies");
        this.currentIndex = 0;
        this.selectedZohoCompany = this.trevorCompanies[this.currentIndex];
      }
    },
    searchTrevorCompanies() {
      this.isLoading = true;

       this.$courier.Zoho.Companies.getData(1, 20, this.search).then(x => {
          this.zohoCompanies = x;
          this.isLoading = false;
      });
    },
    trevorCompanySearchEnter() {
      if (this.selectedZohoCompany) {
        this.showDialog = true;
      }
    },
    dialogPressedEnter() {
      this.currentTrevorCompany.zohoDeskId = this.selectedZohoCompany.id;

        this.$courier.Companies.updateById(this.currentTrevorCompany.id, this.currentTrevorCompany).then(x => {
            this.showNextCompany();
            this.showDialog = false;
            this.search = "";
        });
    },
  },
  data() {
    return {
      showDialog: false,
      zohoCompanies: [],
      isLoading: false,
      trevorCompanies: [],
      selectedZohoCompany: {},
      search: "",
      currentIndex: 0,
      currentTrevorCompany: {},      
    };
  },
};
</script>

<style>
</style>